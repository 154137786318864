import { Box } from "@mui/system";
import Navbar from "./views/Navbar/Navbar.jsx";
import "./scss/App.scss";
import Footer from "./views/Footer/Footer.jsx";
import { useState } from "react";
import { Routers } from "./routes/Routers.jsx";
import { BrowserRouter } from "react-router-dom";
function App() {
  const [hash, setHash] = useState(window.location.hash)
  return (
    <BrowserRouter>
      <Box>
        <Navbar hash={hash} setHash={setHash} />
        <Routers />
        <Footer hash={hash} setHash={setHash} />
      </Box>
    </BrowserRouter>
  );
}

export default App;
